<template>
  <CContainer>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Logs de Envios</h3>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
          <template #saldo_actual="{item, index}">
                <td>
                   {{creditosConversorMoneda(item.saldo_actual)}}
                </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  
  </CContainer>
  
</template>

<script>

export default {
  /*@keypress="isNumber($event)"
    @blur="formatCurrency($event,'blur')"
    @keyup="formatCurrency($event,'')" */
  name: 'LogEnvio',
  data () {
    return {
      items: [],
      items_filter: [],
      fields: [
        { key: 'usuario',label:'Usuario'},
        { key: 'saldo_actual',label:'Creditos Actual'},
        { key: 'cantidad_sms_enviar',label:'Cantidad de sms enviados'},
        { key: 'valor_envio',label:'Valor de Envio'},
        { key: 'fechar_cargue',label:'Fecha de Cargue',_classes: 'font-weight-bold'},
        { key: 'fecha_envio',label:'Fecha de Envio',_classes: 'font-weight-bold'},
        { key: 'ip_envio',label:'Ip de Envio'},
        { key: 'navegador',label:'Navegador'},
      ],
      activePage: 1,
      usuario:{
        name: ''
      },
    }
     
  },
  methods: {
    getLogEnvio(){
       const id = this.$route.params.id
       this.$store.dispatch('auth/loading_http','visible');
       this.$http.get('sms/usuarios/logenvio/'+id).then(response => {
             this.items = response.data;
             this.items.map((item,id) => {  
                if (id%2 == 0) {
                  item['_classes'] = 'table-now'; 
                }
                return item;
              })
             //console.log(this.items)
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    creditosConversorMoneda(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return (formatterDolar.format(value)).replace('$','');
    }
 
  },
  mounted () {
    setTimeout(() => {
      this.getLogEnvio();
    }, 200);
  },
}
</script>
